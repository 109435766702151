import config from '@configFile';

const founder = {
  '@context': 'https://schema.org',
  '@type': 'Person',
  '@id': `${config.SITE_URL}${config.admin.authorLink}/#author`,
  name: `${config.admin.firstName} ${config.admin.lastName}`
};

export const authorSchema = {
  ...founder,
  url: `${config.SITE_URL}${config.admin.authorLink}`,
  image: {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    '@id': 'https://www.jungle.deals/author/sarah/#authorImage',
    url: config.admin.image,
    width: 280,
    height: 280,
    caption: `Image of Author ${config.admin.firstName} ${config.admin.lastName}`
  },
  jobTitle: 'Amazon Deals Expert & Blogger',
  worksFor: {
    '@type': 'Organization',
    name: 'Jungle Deals',
    url: 'https://www.jungle.deals'
  },
  sameAs: [config.FACEBOOK_LINK, config.INSTAGRAM_LINK, config.TIKTOK_LINK],
  description:
    'Sarah Elizabeth is an Amazon deals expert and founder of Jungle Deals. She has been a Prime member since 2006 and is known for finding the best promotions, coupons, and discounts on Amazon.',
  knowsAbout: [
    'Amazon deals',
    'Online shopping',
    'Coupons',
    'Prime Membership perks',
    'E-commerce trends'
  ],
  authorOf: {
    '@type': 'WebPage',
    '@id': 'https://www.jungle.deals/blog',
    url: 'https://www.jungle.deals/blog',
    name: 'Jungle Deals Blog'
  }
};

export const getPageImageSeo = (seoImage: string, currentUrl: string) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ImageObject',
    inLanguage: 'en-US',
    '@id': `${currentUrl}/#primaryimage`,
    url: `${seoImage}`,
    contentUrl: `${seoImage}`
  };
};

// export const seoWebsite = {
//   '@type': 'WebSite',
//   '@id': `${config.SITE_URL}/#website`,
//   url: `${config.SITE_URL}`,
//   name: `${config.app.title}`,
//   description:
//     'The Best Amazon Deals, Coupons, Promo Codes, & Hidden Discounts',
//   publisher: {
//     '@id': `${config.SITE_URL}/#organization`
//   },
//   potentialAction: [searchAction],
//   inLanguage: 'en-US'
// };

export const seoOrganization = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  '@id': `${config.SITE_URL}/#organization`,
  url: config.SITE_URL,
  name: 'Jungle Deals',
  logo: {
    '@type': 'ImageObject',
    url: config.LOGO
  },
  founder: {
    ...founder
  },
  sameAs: [config.FACEBOOK_LINK, config.INSTAGRAM_LINK, config.TIKTOK_LINK],
  contactPoint: {
    '@context': 'https://schema.org',
    '@type': 'ContactPoint',
    contactType: 'customer support',
    email: config.admin.siteEmail,
    url: `${config.SITE_URL}/contact`
  }
};

export const seoWebsite = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  '@id': `${config.SITE_URL}/#website`,
  url: config.SITE_URL,
  name: 'Jungle Deals',
  description:
    'Find the best Amazon Deals, Coupons, Promo Codes, & Hidden Discounts. Updated daily to help you save on top-rated products!',
  publisher: {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    '@id': `${config.SITE_URL}/#organization`,
    name: 'Jungle Deals',
    url: config.SITE_URL,
    logo: {
      '@context': 'https://schema.org',
      '@type': 'ImageObject',
      url: config.LOGO
    },
    founder: {
      ...founder
    }
  },
  author: {
    ...founder,
    url: `${config.SITE_URL}${config.admin.authorLink}`,
    sameAs: [config.FACEBOOK_LINK, config.INSTAGRAM_LINK, config.TIKTOK_LINK]
  },
  potentialAction: {
    '@context': 'https://schema.org',
    '@type': 'SearchAction',
    target: {
      '@context': 'https://schema.org',
      '@type': 'EntryPoint',
      urlTemplate: `${config.SITE_URL}/search?q={search_term_string}`
    },
    'query-input': 'required name=search_term_string'
  },
  inLanguage: 'en-US'
};

export const getSEOSchema = ({
  title,
  image,
  description = config.DESCRIPTION,
  pageType = 'CollectionPage',
  faqs = [],
  faqsName = 'FAQPage',
  faqsDescription = 'Frequently Asked Questions',
  url = config.SITE_URL
}: {
  title: string;
  image?: string[] | null;
  description?: string;
  pageType?: string;
  faqs?: { question: string; answer: string }[];
  faqsName?: string;
  faqsDescription?: string;
  url?: string;
}) => {
  const seoSchema: any = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@context': 'https://schema.org',
        '@type': pageType,
        '@id': `${url}/#${pageType.toLowerCase()}`,
        url: `${config.SITE_URL}/#${pageType.toLowerCase()}`,
        name: title,
        description,
        isPartOf: {
          '@id': `${config.SITE_URL}/#website`
        },
        breadcrumb: {
          '@id': `${url}/#breadcrumb`
        },
        ...(image ? { image } : {}),
        datePublished: new Date().toISOString(),
        dateModified: new Date().toISOString(),
        inLanguage: 'en-US'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        '@id': `${config.SITE_URL}/#website`,
        name: config.app.title,
        url: config.SITE_URL,
        publisher: {
          '@type': 'Organization',
          name: config.app.title,
          logo: {
            '@type': 'ImageObject',
            url: config.LOGO
          }
        }
      },
      {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        '@id': `${config.SITE_URL}/#breadcrumb`,
        name: title,
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Home',
            item: config.SITE_URL
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: title,
            item: `${config.SITE_URL}/${title
              .replace(/\s+/g, '-')
              .toLowerCase()}`
          }
        ]
      }
    ]
  };

  // Add FAQPage only if FAQs are provided
  if (faqs.length > 0) {
    seoSchema['@graph'].push({
      '@type': 'FAQPage',
      '@id': `${config.SITE_URL}/#faq`,
      name: faqsName, // ✅ Add a name for the FAQPage
      description: faqsDescription, // ✅ Add a description
      mainEntity: faqs.map(({ question, answer }) => ({
        '@type': 'Question',
        name: question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: answer
        }
      }))
    });
  }

  return seoSchema;
};

export const getHelmetMeta = ({
  title = "Round up of Amazon's BEST Daily Deals",
  image,
  imageWidth = '800',
  imageHeight = '275',
  description = '',
  url = 'https://www.jungle.deals/daily',
  publishTime = new Date(new Date().setHours(4, 0, 0)).toISOString(),
  ogPath,
  contentType = 'collection'
}: {
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  title?: string;
  description?: string;
  url?: string;
  ogPath?: string;
  publishTime?: string;
  contentType?: string;
}) => {
  const desc = `${description.slice(0, 130).trim()}...`;
  const ret = [
    { property: 'og:type', content: contentType },
    { property: 'og:title', content: title },
    { property: 'og:site_name', content: config.app.title },
    {
      property: 'og:description',
      content: desc || title
    },
    {
      name: 'description',
      property: 'description',
      content: desc || title
    },
    { property: 'og:locale', content: 'en_US' },
    {
      property: 'article:published_time',
      content: publishTime
    },
    {
      property: 'article:modified_time',
      content: publishTime
    }
  ];

  if (ogPath) {
    ret.push({ property: 'og:url', content: ogPath });
  } else if (url) {
    ret.push({ property: 'og:url', content: url });
  }

  if (image) {
    ret.push({ property: 'og:alt', content: title });
    ret.push({ property: 'og:image', content: image });

    if (imageWidth && imageHeight) {
      ret.push({ property: 'og:image:width', content: imageWidth });
      ret.push({ property: 'og:image:height', content: imageHeight });
      ret.push({ property: 'og:width', content: imageWidth });
      ret.push({ property: 'og:height', content: imageHeight });
    }
  }

  return ret;
};
